import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/src/locale/es.ts';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: '#194056',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
};

export default new Vuetify(opts);
