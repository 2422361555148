import Vue from 'vue';
import vuetify from './js/plugins/vuetify';

new Vue({
  vuetify,
  data: {
    loader: null,
    loading: false,
    showPassword: false,
    confirmPassword: '',
  },

  watch: {
    loader() {
      const l = this.loader;

      this[l] = !this[l];
    },
  },

  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.$refs.form.$el.submit();
    },

    submitSearchProfileForm() {
      if (!this.$refs.searchProfileForm.validate()) {
        return;
      }

      this.loading = true;
      this.$refs.searchProfileForm.$el.submit();
    },
  },
}).$mount('#app');
